.libre-franklin-18W {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #4B4848;
}

.libre-franklin-26W {
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: #4B4848;
}

.libre-franklin-18 {
  font-family: Libre Franklin;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #4B4848;
}
