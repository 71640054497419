.cafe-flex-row
{
    display:flex;
    flex-direction:row;
}
.invisible {
  display: none !important;
}
.cafe-flex-col {
  display: flex;
  flex-direction: column;
}

.flex-8 {
  display: flex;
  flex: 8;
}
.flex-4 {
  display: flex;
  flex: 4;
}

.flex-3 {
  display: flex;
  flex: 3;
}

.flex-2 {
  display: flex;
  flex: 2;
}


.flex-1 {
  display: flex;
  flex: 1;
}
.flex-2-important {
  display: flex;
  flex: 2 !important;
}
.flex-08 {
  display: flex;
  flex: 0.8;
}

.flex-grow-2:focus {
  display: flex;
  flex: 2;
}
.flex-04 {
  display: flex;
  flex: 0.4;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

/*Aligements*/

.align-items-center
{
    align-items:center;
}

.center-center
{
    justify-content:center;
    align-items:center;
}
.center-right {
  justify-content: flex-end;
  align-items: center;
}

.center-left {
  justify-content: flex-start;
  align-items: center;
}

.space-evenly-center {
  justify-content: space-evenly;
  align-items: center;
}

.space-between-center {
  justify-content: space-between;
  align-items: center;
}

.text-center
{
    text-align:center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.align-self-center
{
    align-self:center;
}

.align-end {
  justify-content: flex-end;
  align-items: flex-end;
}

.justify-content_space-evenly{
  justify-content: space-evenly;
}
